import React, { useEffect, useState } from "react";
import ButtonPrimary from "../../1-atoms/Buttons/Primary";
import classnames from "classnames";
import LoaderComponent from "../Loader";

interface SubmitButtonProps {
  loaderLabel: string;
  loading: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  loaderLabel,
  loading,
  className,
  ...props
}) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (loading) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [loading]);

  return (
    <ButtonPrimary
      disabled={disabled}
      className={classnames('flex items-center justify-center relative', className)}
      {...props}
    >
      {loading && (
        <LoaderComponent
          color={'white'}
          loaderLabel={loaderLabel}
          style={{ marginRight: '-11px' }}
          className={'absolute t-1/2 m-auto'}
        />
      )}
      <div className={classnames({ invisible: loading })}>{props.children}</div>
    </ButtonPrimary>
  );
};

export default SubmitButton;
